// Generated by Framer (0eadc37)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {Df1pGjnaR: {hover: true}};

const cycleOrder = ["Df1pGjnaR"];

const variantClassNames = {Df1pGjnaR: "framer-v-1vzdfre"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "Df1pGjnaR", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Df1pGjnaR", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-rLQk8", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-1vzdfre", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Df1pGjnaR"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"Df1pGjnaR-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-1qgkhj2"} data-framer-name={"Group 43288"} fill={"rgba(0,0,0,1)"} intrinsicHeight={25} intrinsicWidth={44} layoutDependency={layoutDependency} layoutId={"I667:3797;667:3587"} svg={"<svg width=\"44\" height=\"25\" viewBox=\"0 0 44 25\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<rect opacity=\"0.4\" width=\"44\" height=\"25\" rx=\"12.5\" fill=\"#201747\"/>\n<path d=\"M-3.90768e-07 12.5C-3.90768e-07 14.9723 0.733102 17.389 2.10662 19.4446C3.48014 21.5002 5.43237 23.1024 7.71644 24.0485C10.0005 24.9946 12.5139 25.2421 14.9386 24.7598C17.3634 24.2775 19.5907 23.087 21.3388 21.3388C23.087 19.5907 24.2775 17.3634 24.7598 14.9386C25.2421 12.5139 24.9946 10.0005 24.0485 7.71646C23.1024 5.43238 21.5002 3.48015 19.4446 2.10663C17.389 0.733117 14.9723 0 12.5 0C9.18479 0 6.00537 1.31696 3.66117 3.66117C1.31696 6.00537 -3.90768e-07 9.18479 -3.90768e-07 12.5Z\" fill=\"#201747\"/>\n</svg>\n"} transition={transition} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-rLQk8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rLQk8 * { box-sizing: border-box; }", ".framer-rLQk8 .framer-1vzdfre { height: 25px; overflow: visible; position: relative; width: 44px; }", ".framer-rLQk8 .framer-1qgkhj2 { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-rLQk8 .framer-v-1vzdfre .framer-1vzdfre { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 25
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"HJEY61VEC":{"layout":["fixed","fixed"]}}}
 */
const FramerUH6uvNRCA: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerUH6uvNRCA;

FramerUH6uvNRCA.displayName = "switch";

FramerUH6uvNRCA.defaultProps = {height: 25, width: 44};

addFonts(FramerUH6uvNRCA, [])